import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BiCaretLeft } from "react-icons/bi";
import { TbCurrencyRupee } from "react-icons/tb";
import CartCard from "./CartCard";
import { SERVER_PATH } from "../../config/env";
import { userInfo } from "../../utils/appInfo";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { errorToaster, successToaster } from "../../utils/index";
import { UserContext } from "./../../context/userContext";

export default function Cart() {
  const { updateUserData } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [taxData, setTaxData] = useState([]);
  let [cartValue, setCartValue] = useState(0);
  const [userData, setUserData] = useState({});
  const [courierPrice, setCourierPrice] = useState([]);
  const [stateData, setStateData] = useState({});

  const fetchData = async () => {
    try {
      const { data: cData = [] } = await axios.post(
        `${SERVER_PATH}get/cart.php`,
        {
          user_id: userInfo().email,
        }
      );
      setData(cData);
      updateUserData({ cart: cData });
    } catch (error) {
      setData([]);
    }
  };

  const fetchStateData = async () => {
    await axios
      .post(`${SERVER_PATH}get/user.php`, {
        user_id: userInfo().email,
      })
      .then((res) => {
        setStateData(res.data[0]);
      });
  };

  useEffect(() => {
    if (stateData?.state) courierPriceData(stateData);
  }, [stateData]);

  const postCartData = (i) => {
    axios
      .post(`${SERVER_PATH}put/cart.php`, {
        user_id: userData.email,
        product_id: data[i].product_id,
        quantity: data[i].quantity,
      })
      .then((res) => {
        setCartData(res.data);
      });
  };

  useEffect(() => {
    let value = 0;
    data.map((info) => {
      value = value + info.quantity * info.offer_price;
    });
    setCartValue(value);
  }, [data]);

  const removeProduct = async (id) => {
    await axios
      .post(`${SERVER_PATH}delete/cart.php`, {
        user_id: userData.email,
        product_id: id,
      })
      .then((res) => {
        fetchData();
        errorToaster({ msg: "Item removed from your cart" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const removeAllProduct = async () => {
  //   await axios
  //     .post(`${SERVER_PATH}delete/cart.php`, {
  //       user_id: userData.email,
  //     })
  //     .then((res) => {
  //       fetchData();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const checkoutProduct = async () => {
    let productID = data.map((d) => {
      return d.product_id;
    });
    let quantity = data.map((d) => {
      return d.quantity;
    });
    let product_name = data.map((d) => {
      return d.product_name;
    });
    let payload = {
      user_id: userData.email,
      order_cost: cartValue,
      product_id: productID,
      quantity: quantity,
      product_name: product_name,
    };
    await axios
      .post(`${SERVER_PATH}post/payment.php`, payload)
      .then((res) => {
        window.location.href = res.data.pay_url;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const courierPriceData = async (val = {}) => {
    await axios
      .post(`${SERVER_PATH}get/delivery_cost.php`, {
        state: val.state,
      })
      .then((res) => {
        setCourierPrice(res.data[0]);
      })
      .catch((err) => console.log(err));
  };

  const getTaxData = () => {
    axios.post(`${SERVER_PATH}get/additional_cost.php`, {}).then((res) => {
      setTaxData(res.data[0]);
    });
  };

  const calculateBasePrice = (weight = 0) => {
    let cost = 0;
    if (!weight) {
      return 0;
    }
    if (weight <= 250) {
      cost = courierPrice.g250;
    } else if (weight <= 500) {
      cost = courierPrice.g500;
    } else if (weight <= 3000) {
      let remWeigh = weight - 500;
      let remCalc = Math.ceil(remWeigh / 500);
      cost = remCalc * courierPrice.upto3kg + Number(courierPrice.g500);
    } else {
      let remCalc = Math.ceil(weight / 1000);
      let cost3k = remCalc * courierPrice.above3kg;
      cost = Number(cost3k);
    }
    return cost;
  };
  const getDeliveryCost = () => {
    let weight = 0;
    data.map((item, i) => {
      let maxWeight = item.weight * item.quantity;
      weight = weight + maxWeight;
    });
    let accCost = calculateBasePrice(weight);
    accCost = Number(accCost) + (accCost * taxData.fuel) / 100;
    let netCost = Math.ceil(accCost) + (Math.ceil(accCost) * taxData.gst) / 100;
    return Math.ceil(netCost);
  };

  useEffect(() => {
    setTimeout(async () => {
      await fetchData();
      await fetchStateData();
      setUserData(userInfo());
      getTaxData();
    }, 500);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="h-100 h-custom productPage">
        <div className="container-fluid py-4 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col">
              <div className="card">
                <div className="card-body p-4">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="mb-3">
                        <Link to="/" className="text-body text-decoration-none">
                          <BiCaretLeft
                            className="me-0"
                            style={{ fontSize: "1.5em", marginTop: "-5px" }}
                          />
                          <span>Continue shopping</span>
                        </Link>
                      </div>
                      <hr />

                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <div>
                          <p className="mb-1">Shopping cart</p>
                          <p className="mb-0">
                            You have {data.length} items in your cart
                          </p>
                        </div>
                      </div>
                      <CartCard
                        cartData={(i) => postCartData(i)}
                        data={data}
                        deleteValue={removeProduct}
                        setData={setData}
                      />
                    </div>
                    <div className="col-lg-5">
                      <div className="card bg-success text-white rounded-3">
                        <div className="card-body">
                          <h4 className="text-uppercase">Price Details</h4>
                          <hr className="my-3" />
                          <div className="d-flex justify-content-between">
                            <p className="mb-2 text-uppercase">sub total</p>
                            <p className="mb-2">
                              <TbCurrencyRupee style={{ marginTop: "-3px" }} />
                              {cartValue}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="mb-2 text-uppercase">
                              Delivery charges
                            </p>
                            <p className="mb-2">
                              <TbCurrencyRupee style={{ marginTop: "-3px" }} />
                              {stateData.state != "0" ? getDeliveryCost() : 0}
                              {/* {getDeliveryCost()} */}
                            </p>
                          </div>
                          <hr className="my-3" />
                          <div className="d-flex justify-content-between mb-2">
                            <p className="mb-2 text-uppercase">total price</p>
                            <p className="mb-2">
                              <TbCurrencyRupee style={{ marginTop: "-3px" }} />
                              {stateData.state != "0"
                                ? (cartValue += getDeliveryCost())
                                : cartValue}
                            </p>
                          </div>
                          <button
                            disabled={!cartValue}
                            type="button"
                            className="btn btn-light btn-block btn-sm"
                          >
                            <div className="d-flex justify-content-between">
                              <span
                                target="_blank"
                                className="text-uppercase"
                                onClick={() => {
                                  if (stateData.state == "0") {
                                    alert(
                                      "Please provide your address in your profile page"
                                    );
                                  } else {
                                    checkoutProduct();
                                  }
                                }}
                              >
                                Checkout
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}
