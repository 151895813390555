import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import productOne from "../../assets/img/herbalProduct.webp";
import productTwo from "../../assets/img/gardenProduct.webp";
import productThree from "../../assets/img/yogaProduct.webp";
import productFour from "../../assets/img/spicesProduct.webp";
import productFive from "../../assets/img/foodProduct.jpg";

const titleStyles = {
  fontSize: ".9em",
};

export default function ProductLayout() {
  const productData = [
    {
      path: "/herbalProduct",
      img: productOne,
      title: "Herbal Products",
    },
    {
      path: "/gardenProduct",
      img: productTwo,
      title: "Garden Products",
    },
    {
      path: "/yogaProduct",
      img: productThree,
      title: "Yoga Products",
    },
    {
      path: "/spices",
      img: productFour,
      title: "Spices",
    },
    {
      path: "/foodProduct",
      img: productFive,
      title: "Food Products",
    },
  ];
  return (
    <>
      {productData.map((data, index) => {
        return (
          <Col xs={4} className="col-md mb-4" key={index + "product"}>
            <div className="card h-100">
              <Link to={data.path}>
                <img
                  src={data.img}
                  className="card-img-top"
                  alt="sivamfarms products"
                />
              </Link>
              <div className="card-body">
                <Link
                  to={data.path}
                  className="h6 text-decoration-none text-uppercase text-dark productTitle"
                  style={titleStyles}>
                  {data.title}
                </Link>
              </div>
            </div>
          </Col>
        );
      })}
    </>
  );
}
