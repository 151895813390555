import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";

export const Breadcrumbs = ({ currentPathName }) => {
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>
          <Link
            to="/"
            className="text-success text-decoration-none"
            style={{ textDecoration: "none" }}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{currentPathName}</Breadcrumb.Item>
      </Breadcrumb>
      <hr color="#e0e0e0" />
    </>
  );
};
