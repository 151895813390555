import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import axios from "axios";
import { SERVER_PATH } from "../../../config/env";
import commingSoon from "../../../assets/img/coming soon.jpg";
import { FadeLoader } from "react-spinners";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#000",
};

export default function FoodSingleProduct() {
  const [foodData, setFoodData] = useState([]);

  const fetchData = () => {
    axios
      .post(`${SERVER_PATH}get/all_products.php`, { category: "food" })
      .then((res) => {
        setFoodData(res.data);
      });
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {foodData.length >= 1 ? (
        <>
          {foodData.map((data, index) => {
            return (
              <div className="col-md-3" key={"product" + index}>
                <div className="card mb-4 product-wap rounded-1">
                  <div className="card rounded-0">
                    <img
                      className="card-img rounded-0 img-fluid"
                      style={{ height: "180px", objectFit: "contain" }}
                      src={data.img1}
                      alt={data.product_name}
                    />
                    <div className="card-img-overlay rounded-0 product-overlay d-flex align-items-center justify-content-center">
                      <ul className="list-unstyled">
                        <li>
                          <Link
                            className="btn btn-success text-white mt-2"
                            to={`/product/${data.product_id}`}
                          >
                            <AiOutlineEye
                              style={{ fontSize: "1.3em", marginTop: "-3px" }}
                            />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-body">
                    <Link to="/" className="h3 text-decoration-none">
                      {data.product_name}
                    </Link>
                    <span className="d-block text-secondary my-2">
                      RS.{data.offer_price}
                    </span>
                    <Link to={`/product/${data.product_id}`}>
                      <button className="btn btn-sm w-100 btn-outline-success text-uppercase">
                        Buy Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : foodData.length === 0 ? (
        <div className="col-md-3">
          <div className="card mb-4 product-wap rounded-1">
            <div className="card rounded-0">
              <img
                className="card-img rounded-0 img-fluid"
                style={{ height: "180px", objectFit: "cover" }}
                src={commingSoon}
                alt="comming soon..."
              />
              <div className="card-img-overlay rounded-0 product-overlay d-flex align-items-center justify-content-center">
                <ul className="list-unstyled">
                  <li>
                    <AiOutlineEye
                      style={{ fontSize: "1.3em", marginTop: "-3px" }}
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-body">
              <Link to="/" className="h3 text-decoration-none">
                comming soon...
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <>
          <FadeLoader color="#000" size={250} cssOverride={override} />
          <h2 className="text-center mt-3">Loading...</h2>
        </>
      )}
    </>
  );
}
