import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export const PrivacyPolicy = () => {
  return (
    <>
      <Container>
        <Row className="my-4">
          <Col className="privacyPolicy">
            <h2>Privacy Policy</h2>
            <p>
              Sivam Farms ("Company" or "We") respects your privacy and is
              committed to protecting it through our compliance with this
              policy.
            </p>
            <p>
              This policy describes the types of information we may collect from
              you or that you may provide when you visit the website located at
              www.sivamfarms.com or any mobile application or social media site
              operated by the Company (collectively, our "Website") and our
              practices for collecting, using, maintaining, protecting, and
              disclosing that information.
            </p>
            <h4>This policy applies to information we collect:</h4>
            <ul>
              <li>On this Website.</li>
              <li>
                In email, text, and other electronic messages between you and
                this Website.
              </li>
              <li>
                Through mobile and desktop applications you download from this
                Website, which provide dedicated non-browser-based interaction
                between you and this Website, if any.
              </li>
              <li>
                When you interact with our advertising and applications on
                third-party websites, applications and services, such as our
                Facebook page.
              </li>
            </ul>
            <h4>It does not apply to information collected by us:</h4>
            <ul>
              <li>
                Offline or through any other means, including on any other
                website operated by the Company or any third party (including
                our affiliates and subsidiaries), except as otherwise expressly
                set forth herein;
              </li>
            </ul>
            <p>
              Please read this policy carefully to understand our policies and
              practices regarding your information and how we will treat it. IF
              YOU DO NOT AGREE TO THE TERMS AND CONDITIONS OF THIS PRIVACY
              POLICY, DO NOT USE OUR WEBSITE. By accessing or using this
              Website, you agree to this Privacy Policy. This policy may change
              from time to time. Your continued use of this Website after we
              make changes is deemed to be acceptance of those changes, so
              please check the policy regularly for updates.
            </p>
            <h4>HOW WE USE YOUR PERSONALLY IDENTIFIABLE INFORMATION</h4>
            <h6>Marketing Emails</h6>
            <p>
              The information you have chosen to provide may be used by Sivam
              farms website to create and deliver to you emails such as our
              newsletters, surveys or other email messages containing product
              and event information or promotions. If at any time you decide
              that you would not like to receive these emails, you may select
              the ‘Unsubscribe’ link on the email or edit your information and
              consent to receive email and other communication in the ‘Profile’
              section for registered users.
            </p>
            <h6>Text Messages/Whatsapp</h6>
            <p>
              If you have chosen to leave your mobile phone number on Sivam
              Farms Website, you may receive text message/SMS alerts/Whatsapp
              messages containing product and event information, cosmetics tips
              or promotions on your mobile phone.Sivam Farms does not charge any
              fee for you to receive any text message from us. However, your
              mobile service provider may charge you for sending and/or
              receiving text messages and air-time, as well as any other
              standard applicable rates charged by your mobile service provider.
            </p>
            <h6>Telephone calls</h6>
            <p>
              If you have chosen to leave your mobile phone number or landline
              number on Sivam Farms, you may receive phone calls containing
              product and event information, or promotions on your mobile phone
              or landline phone.
            </p>
            <h6>Communications to Serve You</h6>
            <p>
              If you have chosen to provide us with your contact information, we
              may provide you with service related announcements by any means,
              including email, concerning the website or contact you regarding
              your customer service requests or questions. For example, all
              registered users will receive a welcoming email to confirm their
              registration. These types of communications are necessary to serve
              you, respond to your concerns and to provide the high level of
              customer service that Sivam farms offers its customers.
            </p>
            <h6>Customised Service</h6>
            <p>
              We may use your personal information you have chosen to give us to
              provide you with customised service and use of our website. For
              example, we may suggest products that would be of particular
              interest to you. Such customised information may be conveyed to
              you by way of emails or text messages/sms and phone calls.
            </p>
            <h6>Special Events, Questionnaires and Surveys</h6>
            <p>
              On occasion, Sivam Farms may sponsor special events (such as
              contests, promotions or other offerings), questionnaires and
              surveys. There may be specific rules applicable to these
              promotions that explain how any personal information you provide
              will be processed. We strongly suggest you review any applicable
              rules before participating. To the extent any special event,
              questionnaire or survey is not subject to specific rules, the
              processing of personal information is governed by this Privacy
              Policy and the purposes it sets forth.
            </p>
            <h6>Employment</h6>
            <p>
              If you provide personal information to us with respect to
              employment, such as your resume, we will treat such personal
              information as confidential and use it only to consider you for
              current and future employment opportunities and to contact you
              with respect to employment with us or our affiliates. An
              “Affiliate” is a person or entity that directly or indirectly
              through one or more intermediaries, controls or is controlled by,
              or is under common control with, our company. We will retain your
              information for further recruitment opportunities that may arise,
              unless you tell us that you no longer wish for us to retain your
              information.
            </p>
            <h6>Credit Card , Debit Card and Banking Information</h6>
            <p>
              Sivam Farms does not collect or store Credit Card or Debit Card or
              banking information. Such information may be provided by you
              during the purchase transaction. However this data does not come
              to Sivam farms. It is directly transmitted through the payment
              gateway provider to the payment network or bank . No information
              is stored by Sivam Farms.
            </p>
            <h6>Security</h6>
            <p>
              Sivamfarms uses technical security measures to prevent the loss,
              misuse, alternation or unauthorized disclosure of information
              under our control. Sivamfarms.com uses security measures including
              and not limited to: physical, electronic and managerial procedures
              to safeguard and secure the information we collect online. When we
              ask customers or users to provide financial information (such as a
              credit card number) that data is protected using Secure Sockets
              Layer ("SSL") technology.
            </p>
            <h6>Compliance with Laws and Law Enforcement</h6>
            <p>
              We cooperate with government and law enforcement officials to
              enforce and comply with the law. We will disclose any information
              about Users upon valid request by government or law officials as
              we, in our sole discretion, believe necessary or appropriate to
              respond to claims and legal process (including without limitation
              subpoenas), to protect your property and rights, or the property
              and rights of a third party, to protect the safety of the public
              or any person, or stop activity we consider illegal or unethical.
            </p>
            <h6>Cookies</h6>
            <p>
              Your Internet browser has the in-built facility for storing small
              text files - "cookies" - that hold information which allows a
              website to recognize your account. We use cookies to save your
              preferences and login information, and provide personalized
              functionality. You can reject cookies by changing your browser
              settings, but be aware that this will disable some of the
              functionality on the Sivam farms' website.
            </p>
            <h6>UPDATES TO OUR PRIVACY POLICY</h6>
            <p>
              We may revise this Privacy Policy from time to time. If we decide
              to change our Privacy Policy, we will post the revised policy
              here. We suggest that you periodically consult this Privacy
              Policy. Please find the effective date of the Privacy Policy at
              the top of this page.
            </p>
            <h6>COMMENTS</h6>
            <p>
              We have taken great efforts to ensure that your visit to Sivam
              farms is an excellent and fruitful one and that your privacy is
              constantly respected. If you have any questions, comments or
              concerns about our privacy practices, please contact us by email
              at farmsivam@gmail.com .
            </p>
            <h6>CONCERNING FACEBOOK AND INSTAGRAM</h6>
            <p>
              On our pages, you will find a button linking to our
              Facebook/Instragram page. Please note that, with the use of this
              button, data about the visit to our pages can be linked to your
              Facebook/Instagram profile.
            </p>
            <p>
              If you do not wish to transmit data to Facebook/Instagram, please
              ensure that you are logged out while entering our pages.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};
