import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { SERVER_PATH } from "../../config/env";
import { userInfo } from "../../utils/appInfo";
import axios from "axios";

const borderShadow = {
  backgroundColor: "#fff",
  borderRadius: "5px",
  borderColor: "#e0e0e0",
  borderWidth: "1px",
  borderStyle: "solid",
};
const rounded = {
  borderRadius: "50%",
};

export default function NotificationMsg() {
  const [data, setData] = useState([]);
  let { name = "User", email = "" } = userInfo();

  const fetchData = async () => {
    const { data: cData = [] } = await axios.post(
      `${SERVER_PATH}get/notification.php`,
      {
        user_id: email,
      }
    );
    setData(cData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {data.map((d) => {
        return (
          <Container style={borderShadow} className="mb-2">
            <Row>
              <Col xxl={2} xl={2} lg={2} md={2} sm={2}>
                <div className="p-2">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/4407/4407079.png"
                    alt=""
                    className="img-fluid"
                    style={rounded}
                  />
                </div>
              </Col>
              <Col xxl={10} xl={10} lg={10} md={10} sm={10}>
                <div className="p-2">
                  <h5>{name}</h5>
                  <p className="mb-0">{d?.content}</p>
                  <a href={d?.link} className="text-decoration-none">
                    {d?.link}
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        );
      })}
    </>
  );
}
