import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { BsCoin } from "react-icons/bs";
import { ImTarget } from "react-icons/im";
import { FaChartLine } from "react-icons/fa";
import aboutHero from "../../assets/img/about/hero.jpg";
import inspiration from "../../assets/img/about/inspiration.jpg";
import infrastructure from "../../assets/img/about/Infrastructure.jpg";
import incrediants from "../../assets/img/about/incrediants-standard.jpg";
import cultivation from "../../assets/img/about/Cultivation.jpg";
import AboutCard from "./AboutCard";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fullViewInspiration = () => {
    Swal.fire({
      title: "INSPIRATION",
      text: "In 2020 while most of us had to face the pandemic, we were in need of natural healthy products for our kids to improve the immunity.But we had access to only few and others were not available. So we started to cultivate on our own to satisfy our own needs. We have studied and analysed the benefits of most precious nature’s resources and started to made it available in our daily busy life.We constantly strive to develop products that are natural, safe, gentle, effective and yet environment friendly. We continually seek new techniques and ingredients to ensure that we offer the best possible natural and cost effective products to our consumers.",
    });
  };

  const fullViewInfrastructure = () => {
    Swal.fire({
      title: "INFRASTRUCTURE",
      text: "Our farm is located about 120 kms from Chennai that expands the landscape of about 2.5 Acers own Land. (15 Acers Leased Out) Hence it makes us feasible to cultivate all our ingredients in our farm that is free from Pesticides",
    });
  };

  const fullViewIngrediants = () => {
    Swal.fire({
      title: "INGREDIANTS AND FREE OF HARMFUL CHEMICALS",
      text: "We follow the Sivam farm quality Ingredients Standard in all our proprietary formulations. We work hard to ensure that every ingredient delivers lasting results naturally. We are against using ingredients that promise quick results but are harmful to the skin in the long run. Then Free from petrochemicals, parabens, Genetically modified ingredients and other synthetic ingredients.",
    });
  };

  const fullViewCultivation = () => {
    Swal.fire({
      title: "CULTIVATION",
      text: "We use the highest possible concentration of organic and wild crafted(harvested from their natural or “wild” habitat, for food or medicinal purposes) ingredients in every treatment, and use cold-pressed, certified organic plant oils as the base whereve possible.Plants in Sivam farm are grown in their ideal habitats, which allow them to thrive and their essences to reach peak potential.",
    });
  };

  return (
    <>
      <section class="py-5" style={{ backgroundColor: "#e0e0e0" }}>
        <div class="container">
          <div class="row align-items-center py-5">
            <div class="col-md-8 text-white">
              <h1 className="text-success">About Us</h1>
              <p className="text-dark">
                As we are from the organical dependent family, we had an
                opportunity to know about the importance of our own natural home
                made products through our ancestors. They used to grow them at
                home and consume periodically. We used to prepare our products
                for many generations and shared with our acquaintances and had
                great feedback.
              </p>
            </div>
            <div class="col-md-4">
              <img src={aboutHero} alt="About Hero" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <section class="container py-3">
        <div class="row text-center pt-5 pb-3">
          <div class="col-lg-12 m-auto">
            <h1 class="h1">Our Story</h1>
            <p>
              We Vignesh Karthikeyan (former Manager in Logistics for 15 years)
              and Spouse Sathya Priya, Former IT employee, Holistic
              nutritionist, Founder – Divine Yoga For Women had an urge to quit
              our jobs and there by paving a way to an healthy life. We started
              extensive research on the uses and benefits of essential oils,
              extracts and ingredients that surround our daily lives. Also we
              have attended full time workshop sessions with Nammazhvar in year
              2009 (Indian green crusader, agricultural scientist, environmental
              activist and organic farming expert) which inspired us to proceed
              further.
            </p>
          </div>
        </div>
      </section>
      <section class="container py-1">
        <Row>
          <Col lg={3}>
            <AboutCard
              image={inspiration}
              cardStyle="aboutCard"
              title="Inspiration"
              contents="In 2020 while most of us had to face the pandemic, we were in need of natural healthy products..."
              readMore={() => fullViewInspiration()}
            />
          </Col>
          <Col lg={3}>
            <AboutCard
              image={infrastructure}
              cardStyle="aboutCard"
              title="Infrastructure"
              contents="Our farm is located about 120 kms from Chennai that expands the
              landscape..."
              readMore={() => fullViewInfrastructure()}
            />
          </Col>
          <Col lg={3}>
            <AboutCard
              image={incrediants}
              cardStyle="aboutCard"
              title="Ingredients Standard"
              contents="We follow the Sivam farm quality Ingredients Standard in all our
              proprietary formulations..."
              readMore={() => fullViewIngrediants()}
            />
          </Col>
          <Col lg={3}>
            <AboutCard
              image={cultivation}
              cardStyle="aboutCard"
              title="Cultivation"
              contents="We use the highest possible concentration of organic and wild crafted.."
              readMore={() => fullViewCultivation()}
            />
          </Col>
        </Row>
        <div class="row d-flex justify-content-center my-3">
          <div class="col-md-4 col-lg-4">
            <div class="px-5 services-icon-wap">
              <div class="h1 aboutIcon text-center">
                <ImTarget />
              </div>
              <h2 class="h5 mt-4 text-center">Mission</h2>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "15px",
                  fontWeight: "400",
                }}>
                Plants in Sivam farm are grown in their ideal habitats, which
                allow them to thrive and their essences to reach peak potential.
              </p>
            </div>
          </div>

          <div class="col-md-4 col-lg-4">
            <div class="px-5 services-icon-wap">
              <div class="h1 aboutIcon text-center">
                <FaChartLine />
              </div>
              <h2 class="h5 mt-4 text-center">Vision</h2>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "15px",
                  fontWeight: "400",
                }}>
                Currently we have launched skin/hair care products and
                fertilizers. By 2023 we are planning to emerge into various
                products such as food products, vegetables, fruits, pulses, etc
              </p>
            </div>
          </div>

          <div class="col-md-4 col-lg-4">
            <div class="px-5 services-icon-wap">
              <div class="h1 aboutIcon text-center">
                <BsCoin />
              </div>
              <h2 class="h5 mt-4 text-center">Values</h2>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "15px",
                  fontWeight: "400",
                }}>
                We Sell the Highest Quality Natural and Organic products We
                satisfy and delight our customers Right product at the right
                quantity with right quality at right time.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
