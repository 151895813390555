import React from "react";
import { Card } from "react-bootstrap";

const linkText = {
  textDecoration: "none",
  color: "green",
  cursor: "pointer",
};

const cardStyle = {
  minHeight: "370px",
};

export default function AboutCard(props) {
  return (
    <>
      <Card style={cardStyle} className="mb-4">
        <Card.Img variant="top" src={props.image} />
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <Card.Text>{props.contents}</Card.Text>
          <Card.Link style={linkText} onClick={props.readMore}>
            Read More
          </Card.Link>
        </Card.Body>
      </Card>
    </>
  );
}
