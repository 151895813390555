import React, { useState, useEffect } from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { SERVER_PATH } from "../../config/env";

export default function NavData() {
  const [data, setData] = useState([]);

  const fetchData = () => {
    axios.post(`${SERVER_PATH}get/all_products.php`, {}).then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const herbalProducts = HerbalProducts();

  function HerbalProducts() {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      if (data?.[i]?.category === "herbal")
        arr.push({
          productId: data?.[i]?.product_id,
          productName: data?.[i]?.product_name,
        });
    }
    return arr;
  }

  const gardenProducts = GardenProducts();

  function GardenProducts() {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      if (data?.[i]?.category === "garden")
        arr.push({
          productId: data?.[i]?.product_id,
          productName: data?.[i]?.product_name,
        });
    }
    return arr;
  }

  const yogaProducts = YogaProducts();

  function YogaProducts() {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      if (data?.[i]?.category === "yoga")
        arr.push({
          productId: data?.[i]?.product_id,
          productName: data?.[i]?.product_name,
        });
    }
    return arr;
  }

  const spices = Spices();

  function Spices() {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      if (data?.[i]?.category === "spices")
        arr.push({
          productId: data?.[i]?.product_id,
          productName: data?.[i]?.product_name,
        });
    }
    return arr;
  }

  const foodProducts = FoodProducts();

  function FoodProducts() {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      if (data?.[i]?.category === "food")
        arr.push({
          productId: data?.[i]?.product_id,
          productName: data?.[i]?.product_name,
        });
    }
    return arr;
  }

  const dropDown = [
    {
      title: "Herbal Products",
      dropDownTitle: herbalProducts,
    },
    {
      title: "Garden Products",
      dropDownTitle: gardenProducts,
    },
    {
      title: "Yoga Products",
      dropDownTitle: yogaProducts,
    },
    {
      title: "Spices",
      dropDownTitle: spices,
    },
    {
      title: "Food Products",
      dropDownTitle: foodProducts,
    },
  ];

  return (
    <>
      {dropDown.map((data, index) => {
        return (
          <li className="nav-item" key={index + "dropdown"}>
            <NavDropdown title={data.title} id="basic-nav-dropdown">
              {data.dropDownTitle.map((d, i) => {
                return (
                  <NavDropdown.Item
                    style={{ fontWeight: "400" }}
                    as={Link}
                    to={`/product/${d.productId}`}
                    key={i + "dropdownItem"}>
                    {d.productName}
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown>
          </li>
        );
      })}
    </>
  );
}
