import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { gapi } from "gapi-script";
// import { clienId } from "../Login/globalMethods";
import { Container, Accordion, ListGroup, Row, Col } from "react-bootstrap";
import "../../assets/css/profile.css";
import { IoIosArrowForward } from "react-icons/io";
import { GrFormAdd } from "react-icons/gr";
import { TbCurrencyRupee } from "react-icons/tb";
import UserLogout from "../Login/userLogout";
import { AddressForm } from "../Forms/addressForm";
import { userInfo } from "../../utils/appInfo";
import NotificationMsg from "../Notification";
import axios from "axios";
import { SERVER_PATH } from "../../config/env";
import { ToastContainer } from "react-toastify";

export default function Profile() {
  const [data, setData] = useState([]);
  const [state, setState] = useState("profile");
  const [formData, setFormData] = useState(false);
  const [userData, setUserData] = useState(false);

  const showForm = (clickData) => {
    setFormData(clickData);
  };

  const eventHandler = (clickState) => {
    setState(clickState);
  };

  const fetchData = async () => {
    await axios
      .post(`${SERVER_PATH}get/order.php`, {
        user_id: userData.email,
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [addressData, setAddressData] = useState([]);

  const addressFetchData = async () => {
    await axios
      .post(`${SERVER_PATH}get/user.php`, {
        user_id: userData.email,
      })
      .then((res) => {
        setAddressData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setUserData(userInfo());
      window.scrollTo(0, 0);
      // fetchData();
    }, 1000);

    // function start() {
    //   gapi.client.init({
    //     clientId: clienId,
    //     scope: "email",
    //   });
    // }
    // gapi.load("client:auth2", start);
  }, []);

  return (
    <>
      <Container fluid className="productPage">
        <div className="main-body">
          <div className="row gutters-sm">
            <div className="col-md-4 mb-3">
              <div className="card shadow-sm">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <img
                      src={
                        userData.picture
                          ? userData.picture
                          : "https://bootdey.com/img/Content/avatar/avatar7.png"
                      }
                      alt="profile"
                      className="rounded-circle"
                      width="150"
                    />
                    <div className="mt-3">
                      <h4>{userData.name}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-3">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Account Information</Accordion.Header>
                    <Accordion.Body>
                      <ListGroup>
                        <ListGroup.Item onClick={() => eventHandler("profile")}>
                          <IoIosArrowForward />
                          Profile Information
                        </ListGroup.Item>
                        <ListGroup.Item
                          onClick={() => {
                            eventHandler("address");
                            addressFetchData();
                          }}>
                          <IoIosArrowForward />
                          Address Information
                        </ListGroup.Item>
                        <ListGroup.Item
                          onClick={() => {
                            eventHandler("myOrders");
                            fetchData();
                          }}>
                          <IoIosArrowForward />
                          My Orders
                        </ListGroup.Item>
                        <ListGroup.Item
                          onClick={() => eventHandler("notifocation")}>
                          <IoIosArrowForward />
                          Notifications
                        </ListGroup.Item>
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card mb-3">
                {state === "profile" ? (
                  <div className="card-body detailsDiv">
                    <Row>
                      <div className="mb-4 d-flex align-items-center">
                        <span className="h4">Profile Details</span>
                        {/* <span className="btn" style={{ color: "blue" }}>
                          Edit
                        </span> */}
                      </div>
                    </Row>
                    <Row>
                      <div className="col-sm-3">
                        <h6 className="mb-0">Username</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        <input
                          type="text"
                          placeholder={userData.name}
                          disabled
                          tyle={{ width: "100%" }}
                        />
                      </div>
                    </Row>
                    <hr />
                    <Row>
                      <div className="col-sm-3">
                        <h6 className="mb-0">Email</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        <input
                          type="email"
                          placeholder={userData.email}
                          disabled
                          style={{ width: "100%" }}
                        />
                      </div>
                    </Row>
                    <hr />
                    <Row>
                      <div className="col-sm-12">
                        <UserLogout />
                      </div>
                    </Row>
                  </div>
                ) : state === "address" ? (
                  <div className="card-body addressDiv">
                    <Row>
                      <div>
                        <h4 className="mb-3">Manage Addresses</h4>
                        <div>
                          <button
                            className="d-flex align-items-center"
                            onClick={() => showForm(true)}>
                            <GrFormAdd />
                            Add new address
                          </button>
                          {formData && (
                            <AddressForm
                              hide={() => showForm(false)}
                              addressData={addressFetchData}
                            />
                          )}
                          <hr style={{ color: "#000" }} />
                          <h4 className="mb-3">My Address</h4>
                          <div>
                            {addressData.map((d, i) => {
                              return d.state == "0" ? (
                                <h4>Enter your address</h4>
                              ) : (
                                <address>
                                  <p className="mb-0">{d.user_name},</p>
                                  <p className="mb-0">{d.mobile_no},</p>
                                  <p className="mb-0">{d.address}</p>
                                  <p className="mb-0">{d.state}</p>
                                  <p>{d.pincode}</p>
                                </address>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                ) : state === "myOrders" ? (
                  <div className="card-body addressDiv">
                    <Row>
                      <div>
                        <h4 className="mb-3">My Orders</h4>
                        <hr color="#e0e0e0" />
                        <Col
                          className="mb-3 shadow-none"
                          style={{ maxHeight: "400px", overflowY: "scroll" }}>
                          {data.map((d) => {
                            return (
                              <>
                                <div
                                  className="card-body mb-2 d-none d-sm-block"
                                  style={{
                                    borderColor: "#e0e0e0",
                                    borderStyle: "solid",
                                    borderWidth: "1px",
                                  }}>
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex flex-row align-items-center">
                                      <div
                                        style={{ width: "65px" }}
                                        className="d-none d-sm-block">
                                        <Link
                                          to={`/product/${d.product_id}`}
                                          className="text-decoration-none">
                                          <img
                                            src={d.img1}
                                            className="img-fluid"
                                            alt=""
                                            style={{
                                              width: "65px",
                                              height: "50px",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </Link>
                                      </div>
                                      <div
                                        className="ms-3"
                                        style={{ width: "180px" }}>
                                        <Link
                                          to={`/product/${d.product_id}`}
                                          className="text-decoration-none text-dark">
                                          <h6 className="text-uppercase">
                                            {d?.product_name}
                                            <small
                                              className="text-secondary ms-1"
                                              style={{ fontSize: "12px" }}>
                                              {d?.weight}Gms
                                            </small>
                                          </h6>
                                          <p className="small mb-0 text-uppercase text-secondary">
                                            {d?.category}
                                          </p>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row align-items-center">
                                      <div style={{ width: "100px" }}>
                                        <h6>Status</h6>
                                        <p className="small mb-0">
                                          {d?.status}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row align-items-center">
                                      <div style={{ width: "80px" }}>
                                        <h5>{d?.quantity}</h5>
                                        <p className="small mb-0">Qty</p>
                                      </div>
                                      <div style={{ width: "100px" }}>
                                        <h5 className="mb-0">
                                          <TbCurrencyRupee
                                            style={{ marginTop: "-3px" }}
                                          />
                                          {d.offer_price * d.quantity}
                                        </h5>
                                        <p className="small mb-0 text-center">
                                          Price
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* mobile cart div */}
                                <div
                                  className="card-body mb-2 d-block d-sm-none"
                                  style={{
                                    borderColor: "#e0e0e0",
                                    borderStyle: "solid",
                                    borderWidth: "1px",
                                  }}>
                                  <div className="d-flex flex-row align-items-center">
                                    <div style={{ width: "75px" }}>
                                      <Link
                                        to={`/product/${d.product_id}`}
                                        className="text-decoration-none">
                                        <img
                                          src={d.img1}
                                          className="img-fluid mb-3"
                                          alt=""
                                          style={{
                                            width: "65px",
                                            height: "50px",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </Link>
                                    </div>
                                    <div style={{ width: "200px" }}>
                                      <Link
                                        to={`/product/${d.product_id}`}
                                        className="text-decoration-none text-dark">
                                        <h5 className="text-uppercase">
                                          {d?.product_name}
                                          <small
                                            className="text-secondary ms-1"
                                            style={{ fontSize: "12px" }}>
                                            {d?.weight}Gms
                                          </small>
                                        </h5>
                                        <p className="small mb-3 text-uppercase text-secondary">
                                          {d?.category}
                                        </p>
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex flex-row align-items-center">
                                      <div style={{ width: "100px" }}>
                                        <h6>Status</h6>
                                        <p className="small mb-0">
                                          {d?.status}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row align-items-center">
                                      <div style={{ width: "100px" }}>
                                        <h5>{d?.quantity}</h5>
                                        <p className="small mb-0">Qty</p>
                                      </div>
                                      <div style={{ width: "80px" }}>
                                        <h5 className="mb-0">
                                          <TbCurrencyRupee
                                            style={{ marginTop: "-3px" }}
                                          />
                                          {d.offer_price * d.quantity}
                                        </h5>
                                        <p className="small mb-0 text-center">
                                          Price
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </Col>
                      </div>
                    </Row>
                  </div>
                ) : (
                  <div className="card-body addressDiv">
                    <Row>
                      <div>
                        <h4 className="mb-3">Notifications</h4>
                        <hr color="#e0e0e0" />
                        <NotificationMsg />
                      </div>
                    </Row>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <ToastContainer />
    </>
  );
}
