import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppHeader from "../components/Header";
import Home from "../components";
import HerbalProduct from "../components/Products/Herbal";
import GardenProduct from "../components/Products/Garden";
import Spices from "../components/Products/Spices";
import FoodProduct from "../components/Products/Food";
import Cart from "../components/Cart";
import MobNavbar from "../components/NavBar/Mobile";
import Profile from "../components/Profile";
import Login from "../components/Login";
import ErrorPage from "../components/NotFound";
import UserContextProvider from "../context/userContext";
import { PrivateRouter } from "../components/Router/PrivateRouter";
import Product from "../components/Products";
import About from "../components/About";
import Footer from "../components/Footer";
import YogaProduct from "../components/Products/Yoga";
import { PrivacyPolicy } from "../components/Privacy Policy";
import { TermsConditions } from "../components/Terms Conditions";

function App() {
  return (
    <>
      <BrowserRouter path="/" basename={process.env.PUBLIC_URL}>
        <UserContextProvider>
          <AppHeader />
          <MobNavbar />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route
              path="/cart/:productId"
              element={
                <PrivateRouter>
                  <Cart />
                </PrivateRouter>
              }
            />
            <Route
              path="/profile/:productId"
              element={
                <PrivateRouter>
                  <Profile />
                </PrivateRouter>
              }
            />
            <Route path="/herbalProduct" element={<HerbalProduct />} />
            <Route path="/gardenProduct" element={<GardenProduct />} />
            <Route path="/yogaProduct" element={<YogaProduct />} />
            <Route path="/spices" element={<Spices />} />
            <Route path="/foodProduct" element={<FoodProduct />} />
            {/* others */}
            <Route path="/product/:productId" element={<Product />} />
            <Route path="/policy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <Footer />
        </UserContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
