import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BiRupee } from "react-icons/bi";
import { HiBadgeCheck } from "react-icons/hi";
import { BsBookmarkStarFill, BsStarFill } from "react-icons/bs";
import axios from "axios";
import Rating from "../Rating";
import { ToastContainer } from "react-toastify";
import { SERVER_PATH } from "../../config/env";
import { userInfo } from "../../utils/appInfo";
import { successToaster } from "../../utils/index";
import { getLocalStorage } from "./../../utils/helper";

export default function Product(props) {
  const isLogin = getLocalStorage("uid") ? true : false;
  let nav = useNavigate();
  const [count, setCount] = useState(1);
  const [ratings, setRatings] = useState(0);
  const [allRating, setAllRating] = useState();
  const [data, setData] = useState([]);
  const param = useParams();
  const [userData, setUserData] = useState(false);
  // const [userRating, setUserRating] = useState([]);

  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const handleDecrement = () => {
    if (count != 1) setCount((prevCount) => prevCount - 1);
  };

  const toggleState = (clickState) => {
    setRatings(clickState);
  };

  const fetchData = () => {
    axios
      .post(`${SERVER_PATH}get/product.php`, {
        product_id: param.productId,
      })
      .then((res) => {
        let info = res.data.length ? res.data[0] : {};
        setData(info);
        setCount(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postData = async (e) => {
    if (isLogin === false) {
      nav("/profile/:productId");
    } else {
      e.preventDefault();
      let info = {
        product_id: param.productId,
        user_id: userData.email,
        quantity: count,
      };

      axios
        .post(`${SERVER_PATH}post/cart.php`, { ...info })
        .then(() => {
          successToaster({ msg: "Added to cart" });
          setCount(1);
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const buyPostData = async (e) => {
    if (isLogin === false) {
      nav("/profile/:productId");
    } else {
      e.preventDefault();
      let info = {
        product_id: param.productId,
        user_id: userData.email,
        quantity: count,
      };

      axios
        .post(`${SERVER_PATH}post/cart.php`, { ...info })
        .then((res) => {
          nav("/cart/:productId");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const swapFocusImg = (key) => {
    let info = { ...data };
    info[key] = info.img1;
    info.img1 = data[key];
    setData(info);
  };

  const overallRating = async () => {
    await axios
      .post(`${SERVER_PATH}get/ratings.php`, {
        product_id: param.productId,
      })
      .then((res) => {
        setAllRating(res.data);
      });
  };

  // const getUserRating = async () => {
  //   await axios
  //     .post(`${SERVER_PATH}get/ratings.php`, {
  //       user_id: userData.email,
  //       product_id: param.productId,
  //     })
  //     .then((res) => {
  //       setUserRating(res.data.rating);
  //     });
  // };

  const submitRating = async (rate) => {
    axios
      .post(`${SERVER_PATH}post/ratings.php`, {
        user_id: userData.email,
        product_id: param.productId,
        rating: rate,
      })
      .then((res) => {
        successToaster({ msg: "Rating added successfully" });
        overallRating();
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setUserData(userInfo());
      fetchData();
      overallRating();
      window.scrollTo(0, 0);
    }, 200);
  }, [param.productId]);

  return (
    <>
      <section className="productPage" key={data.product_id}>
        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-5 mt-5">
              <div className="card mb-3">
                <img
                  className="card-img img-fluid productDetailImg"
                  src={data.img1}
                  alt="sivamfarms products"
                  id="product-detail"
                />
              </div>
              <div className="row">
                <div className="col-1 align-self-center"></div>
                <div
                  id="multi-item-example"
                  className="col-10 carousel slide carousel-multi-item"
                  data-bs-ride="carousel">
                  <div
                    className="carousel-inner product-links-wap"
                    role="listbox">
                    <div className="carousel-item active">
                      <div className="row">
                        <div
                          className="col-4"
                          onClick={() => {
                            swapFocusImg("img2");
                          }}>
                          <img
                            className="card-img img-fluid productDetailSubImg"
                            src={data.img2}
                            alt="sivamfarms products"
                          />
                        </div>
                        <div
                          className="col-4"
                          onClick={() => {
                            swapFocusImg("img3");
                          }}>
                          <img
                            className="card-img img-fluid productDetailSubImg"
                            src={data.img3}
                            alt="sivamfarms products"
                          />
                        </div>
                        <div
                          className="col-4"
                          onClick={() => {
                            swapFocusImg("img4");
                          }}>
                          <img
                            className="card-img img-fluid productDetailSubImg"
                            src={data.img4}
                            alt="sivamfarms products"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-1 align-self-center"></div>
              </div>
            </div>
            <div className="col-lg-7 mt-5">
              <div className="card">
                <div className="card-body">
                  <h1 className="h5 text-uppercase productDetailTitle">
                    {data.product_name}{" "}
                    <small style={{ fontSize: "15px" }}>
                      ({data.weight} Gms.)
                    </small>
                  </h1>
                  <p>
                    <span
                      className="list-inline-item text-dark fw-700"
                      style={{ fontSize: "1.4em" }}>
                      <BiRupee style={{ marginTop: "-3px" }} />
                      {data.offer_price}
                    </span>
                    <span
                      className="list-inline-item text-secondary"
                      style={{
                        fontSize: "1em",
                        textDecoration: "line-through",
                      }}>
                      <BiRupee style={{ marginTop: "-3px" }} />
                      {data.price}
                    </span>
                    <span
                      className="list-inline-item"
                      style={{ fontSize: "1em", color: "green" }}>
                      {data.offer}%{" "}
                      <small style={{ fontSize: ".7em" }}>Discount</small>
                    </span>
                  </p>
                  <form>
                    <input
                      type="hidden"
                      name="product-title"
                      value="Activewear"
                    />
                    <div className="col-auto">
                      <ul className="list-inline pb-3">
                        <li className="list-inline-item text-right">
                          Quantity
                          <input
                            type="hidden"
                            name="product-quanity"
                            id="product-quanity"
                            value="1"
                          />
                        </li>
                        <li className="list-inline-item">
                          <span
                            className="btn btn-sm btn-success"
                            id="btn-minus"
                            onClick={handleDecrement}>
                            -
                          </span>
                        </li>
                        <li className="list-inline-item">
                          <span
                            className="badge bg-light text-dark"
                            id="var-value">
                            {count}
                          </span>
                        </li>
                        <li className="list-inline-item">
                          <span
                            className="btn btn-sm btn-success"
                            id="btn-plus"
                            onClick={handleIncrement}>
                            +
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="row pb-3">
                      <div className="col d-grid">
                        {data.status == 0 ? (
                          <button
                            disabled
                            onClick={buyPostData}
                            type="submit"
                            className="btn btn-success btn-sm py-2 text-uppercase"
                            name="submit"
                            value="buy"
                            style={{
                              borderRadius: "0",
                              letterSpacing: ".2px",
                              fontWeight: "500",
                            }}>
                            Buy Now
                          </button>
                        ) : (
                          <button
                            onClick={buyPostData}
                            type="submit"
                            className="btn btn-success btn-sm py-2 text-uppercase"
                            name="submit"
                            value="buy"
                            style={{
                              borderRadius: "0",
                              letterSpacing: ".2px",
                              fontWeight: "500",
                            }}>
                            Buy Now
                          </button>
                        )}
                      </div>
                      <div className="col d-grid">
                        {data.status == 0 ? (
                          <button
                            disabled
                            onClick={postData}
                            type="submit"
                            className="btn btn-warning btn-sm py-2 text-uppercase"
                            name="submit"
                            value="addtocard"
                            style={{
                              borderRadius: "0",
                              letterSpacing: ".2px",
                              fontWeight: "500",
                            }}>
                            Add To Cart
                          </button>
                        ) : (
                          <button
                            onClick={postData}
                            type="submit"
                            className="btn btn-warning btn-sm py-2 text-uppercase"
                            name="submit"
                            value="addtocard"
                            style={{
                              borderRadius: "0",
                              letterSpacing: ".2px",
                              fontWeight: "500",
                            }}>
                            Add To Cart
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                  <h6
                    className="text-uppercase mt-3"
                    style={{ color: "#757575" }}>
                    Description:
                  </h6>
                  <p
                    style={{
                      fontSize: "15px",
                      textAlign: "justify",
                      lineHeight: "1.7em",
                      fontWeight: "350",
                    }}>
                    {data.description}
                  </p>
                  <h6
                    className="text-uppercase mt-3"
                    style={{ color: "#000", fontSize: "15px" }}>
                    <HiBadgeCheck
                      style={{
                        marginTop: "-4px",
                        color: "green",
                        fontSize: "20px",
                      }}
                      className="me-1"
                    />
                    Ingredients:
                  </h6>
                  <ul className="text-capitalize pb-2 ingrediants">
                    <li>{data.ingredients}</li>
                  </ul>
                  <h6
                    className="text-uppercase mt-3"
                    style={{ color: "#000", fontSize: "15px" }}>
                    <HiBadgeCheck
                      style={{
                        marginTop: "-4px",
                        color: "green",
                        fontSize: "20px",
                      }}
                      className="me-1"
                    />
                    Methods of Application:
                  </h6>
                  <p
                    style={{
                      fontSize: "15px",
                      textAlign: "justify",
                      lineHeight: "1.7em",
                      fontWeight: "350",
                    }}>
                    {data.instructions}
                  </p>
                </div>
              </div>
              <div className="card mt-2">
                <div className="card-body">
                  <div className="d-flex align-items-center position-relative ratingDiv">
                    <h1
                      className="h5 text-capitalize"
                      style={{ color: "#000", fontSize: "20px" }}>
                      Ratings
                    </h1>
                    <button
                      className="position-absolute end-0"
                      onClick={(e) => {
                        toggleState(1);
                      }}>
                      Rate Product
                    </button>
                  </div>
                  <h6
                    className="text-uppercase mt-3 mb-3"
                    style={{ color: "#000", fontSize: "15px" }}>
                    <BsBookmarkStarFill
                      style={{
                        marginTop: "-4px",
                        color: "#f39c12",
                        fontSize: "20px",
                      }}
                      className="me-1"
                    />
                    Overall Ratings
                  </h6>
                  <div className="d-flex align-items-center">
                    <span style={{ fontSize: "1.5em" }}>{allRating}</span>
                    &nbsp;
                    <BsStarFill />
                    &nbsp;
                  </div>
                </div>
                {ratings === 1 ? (
                  <div className="card-body">
                    <h5>Enter Your Ratings</h5>
                    <Rating
                      rating={ratings}
                      onRating={(rate) => {
                        setRatings(rate);
                      }}
                      submitRating={submitRating}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}
