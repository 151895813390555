import axios from "axios";
import React, { useState } from "react";
import { SERVER_PATH } from "../../config/env";
import { successToaster } from "../../utils/index";
import { userInfo } from "../../utils/appInfo";

export const AddressForm = (props) => {
  const { hide, addressData } = props;
  let { imageUrl = "", email = "" } = userInfo();
  let adddressInfo = {
    mobile_no: "",
    address: "",
    pincode: "",
    user_id: email,
    user_name: "",
    avatar: imageUrl,
    status: "1",
    state: "",
  };

  const url = `${SERVER_PATH}put/user.php`;

  const [data, setData] = useState(adddressInfo);

  const dataHandler = (e) => {
    const arr = { ...data };
    arr[e.target.name] = e.target.value;
    setData(arr);
  };

  const addressSubmit = async (e) => {
    e.preventDefault();

    let info = { ...data };
    await axios
      .post(url, {
        ...info,
        user_id: email,
      })
      .then((res) => {
        setData(adddressInfo);
        addressData();
        successToaster({ msg: "Address added successfully" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <form onSubmit={addressSubmit}>
        <div className="row g-3 my-1">
          <div className="col-md-4">
            <label for="validationCustom02" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              required
              name="user_name"
              placeholder="Enter fullname"
              value={data.user_name}
              onChange={(e) => dataHandler(e)}
            />
          </div>
          <div className="col-md-4">
            <label for="validationCustom02" className="form-label">
              Mobile Number
            </label>
            <input
              type="number"
              className="form-control"
              required
              name="mobile_no"
              placeholder="Enter 10 digit number"
              maxLength="10"
              value={data.mobile_no > 0 && data.mobile_no}
              onChange={(e) => dataHandler(e)}
            />
          </div>
          <div class="col-md-4">
            <label for="inputState" class="form-label">
              State
            </label>
            <select
              id="inputState"
              class="form-select"
              name="state"
              value={data.state}
              onChange={(e) => dataHandler(e)}>
              <option selected>Choose State...</option>
              <option value="andhra pradesh">Andhra Pradesh</option>
              <option value="arunachal pradesh">Arunachal Pradesh</option>
              <option value="assam">Assam</option>
              <option value="bihar">Bihar</option>
              <option value="chhattisgarh">Chhattisgarh</option>
              <option value="goa">Goa</option>
              <option value="gujarat">Gujarat</option>
              <option value="haryana">Haryana</option>
              <option value="himachal pradesh">Himachal Pradesh</option>
              <option value="jharkhand">Jharkhand</option>
              <option value="karnataka">Karnataka</option>
              <option value="kerala">Kerala</option>
              <option value="madhya pradesh">Madhya Pradesh</option>
              <option value="maharashtra">Maharashtra</option>
              <option value="manipur">Manipur</option>
              <option value="meghalaya">Meghalaya</option>
              <option value="mizoram">Mizoram</option>
              <option value="nagaland">Nagaland</option>
              <option value="odisha">Odisha</option>
              <option value="punjab">Punjab</option>
              <option value="rajasthan">Rajasthan</option>
              <option value="sikkim">Sikkim</option>
              <option value="tamilnadu">Tamil Nadu</option>
              <option value="Telangana">Telangana</option>
              <option value="Tripura">Tripura</option>
              <option value="uttar pradesh">Uttar Pradesh</option>
              <option value="uttarakhad">Uttarakhad</option>
              <option value="west bengal">West Bengal</option>
              <option value="andaman and nicobar islands">
                Andaman and Nicobar Islands
              </option>
              <option value="chandigarh">Chandigarh</option>
              <option value="dadar and nagar haveli">
                Dadar and Nagar Haveli
              </option>
              <option value="daman and diu">Daman and Diu</option>
              <option value="delhi">Delhi</option>
              <option value="lakshadweep">Lakshadweep</option>
              <option value="puducherry">Puducherry</option>
              <option value="jammu and kashmir">Jammu and Kashmir</option>
              <option value="ladakh">Ladakh</option>
            </select>
          </div>
          <div className="col-md-12">
            <label for="validationCustom03" className="form-label">
              Address
            </label>
            <textarea
              className="form-control"
              required
              name="address"
              value={data.address}
              placeholder="Enter your address"
              onChange={(e) => dataHandler(e)}></textarea>
          </div>
          <div className="col-md-4 mb-3">
            <label for="validationCustom02" className="form-label">
              Pincode
            </label>
            <input
              type="number"
              className="form-control"
              required
              name="pincode"
              placeholder="Enter Pincode"
              maxLength="10"
              value={data.pincode}
              onChange={(e) => dataHandler(e)}
            />
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" value="Submit" />
            &nbsp;
            <input
              type="submit"
              className="btn btn-warning"
              value="Cancel"
              onClick={hide}
            />
          </div>
        </div>
      </form>
    </>
  );
};
