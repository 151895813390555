import React, { useContext, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GrMail } from "react-icons/gr";
import { MdCall } from "react-icons/md";
import {
  AiOutlineShoppingCart,
  AiOutlineUser,
  AiOutlineSearch,
} from "react-icons/ai";
import "../../assets/css/templatemo.css";
import Logo from "../../assets/img/logo.png";
import instaLogo from "../../assets/img/instagram.png";
import NavData from "../NavBar/navData";
import { UserContext } from "./../../context/userContext";

export default function AppHeader() {
  const { userData } = useContext(UserContext);

  useEffect(() => {}, [userData.cart]);

  return (
    <>
      <Nav
        className="navbar navbar-expand-lg bg-dark navbar-light d-none d-lg-block"
        id="templatemo_nav_top"
      >
        <div className="container-fluid text-light">
          <div className="w-100 d-flex justify-content-between">
            <div>
              <GrMail className="me-2 mb-1" />
              <a
                className="navbar-sm-brand text-light text-decoration-none me-2"
                href="mailto:farmsivam@gmail.com"
              >
                farmsivam@gmail.com
              </a>
              <MdCall className="me-2 mb-1" />
              <a
                className="navbar-sm-brand text-light text-decoration-none"
                href="tel:9840283274"
              >
                9840283274
              </a>
            </div>
            <div>
              <a
                href="https://instagram.com/sivamfarms.organics?igshid=MzRlODBiNWFlZA=="
                target="blank"
                className="text-light text-decoration-none"
              >
                <img
                  src={instaLogo}
                  height="13px"
                  width="auto"
                  className="me-1"
                  alt="sivamfarms instagram"
                />
                sivamfarms.organics
              </a>
            </div>
          </div>
        </div>
      </Nav>
      <Nav
        className="navbar navbar-expand-lg navbar-light shadow d-none d-lg-block"
        style={{
          backgroundColor: "#fff",
          position: "sticky",
          top: 0,
          zIndex: 3,
        }}
      >
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <Link
            className="logo align-self-center"
            to="/"
            style={{ width: "25%" }}
          >
            <img src={Logo} alt="sivam farms logo" height="auto" width="50%" />
          </Link>

          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#templatemo_main_nav"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="align-self-center collapse navbar-collapse flex-fill  d-lg-flex justify-content-lg-between"
            id="templatemo_main_nav"
          >
            <div className="flex-fill">
              <ul className="nav navbar-nav d-flex justify-content-between mx-lg-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <NavData />
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/cart/:productId"
                    className="nav-icon position-relative text-decoration-none"
                  >
                    <AiOutlineShoppingCart style={{ fontSize: "1.7em" }} />
                    <label className="position-absolute top-0 left-100 translate-middle badge rounded-pill bg-success text-light">
                      {userData?.cart.length}
                    </label>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/profile/:productId"
                    className="nav-icon position-relative text-decoration-none"
                  >
                    <AiOutlineUser style={{ fontSize: "1.7em" }} />
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div className="navbar align-self-center d-flex">
              <div className="d-lg-block flex-sm-fill mt-3 mb-4 col-7 col-sm-auto pr-3">
                <Link
                  className="nav-icon d-none d-lg-inline"
                  href="#one"
                  data-bs-toggle="modal"
                  data-bs-target="#templatemo_search_lg">
                  <AiOutlineSearch style={{ fontSize: "1.7em" }} />
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </Nav>
      {/* <div
        className="modal fade bg-white"
        id="templatemo_search_lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="w-100 pt-1 mb-5 text-right">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <form
            action=""
            method="get"
            className="modal-content modal-body border-0 p-0">
            <div className="input-group mb-2">
              <input
                type="text"
                className="form-control"
                id="inputModalSearch"
                name="q"
                placeholder="Search ..."
              />
              <button
                type="submit"
                className="input-group-text bg-success text-light">
                <AiOutlineSearch />
              </button>
            </div>
          </form>
        </div>
      </div> */}
    </>
  );
}
