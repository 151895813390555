import React from "react";
import { Link } from "react-router-dom";
import GardenSingleProduct from "./Garden";
import { Breadcrumbs } from "./../../Breadcrumb/index";

export default function GardenProduct() {
  return (
    <div className="productPage">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <Breadcrumbs currentPathName="Garder Products" />
              <div className="col-md-6">
                <ul className="list-inline shop-top-menu pb-3 pt-1">
                  <li className="list-inline-item">
                    <Link
                      className="h3 text-dark text-decoration-none mr-3 text-uppercase"
                      to="/gardenProduct"
                    >
                      Garden Products
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <GardenSingleProduct />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
