import React from "react";
import { Link } from "react-router-dom";
import { MdCall } from "react-icons/md";
import { GrMail } from "react-icons/gr";
import { HiLocationMarker } from "react-icons/hi";
import { BsFillSuitHeartFill } from "react-icons/bs";
import instaLogo from "../../assets/img/instagram.png";

const Footer = () => {
  return (
    <>
      <footer className="bg-dark" id="tempaltemo_footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4 pt-5">
              <h2 className="h2 text-success border-bottom pb-3 border-light logo">
                Sivam Farms
              </h2>
              <ul className="list-unstyled text-light footer-link-list">
                <li>
                  <HiLocationMarker className="me-2 mb-1 text-white" />
                  NO-179/A PERIYA THERU,
                  <br />
                  PERIYA ILLAVANTHANGAL,
                  <br />
                  REDDYKUPPAM,
                  <br />
                  VANDAVASI-604 502.
                </li>
                <li>
                  <MdCall className="me-2 mb-1" />
                  <a className="text-decoration-none" href="tel:9840283274">
                    9840283274
                  </a>
                </li>
                <li>
                  <GrMail className="me-2 mb-1" />
                  <a
                    className="text-decoration-none"
                    href="mailto:farmsivam@gmail.com"
                  >
                    farmsivam@gmail.com
                  </a>
                </li>
                <li>
                  <img
                    src={instaLogo}
                    height="15px"
                    width="auto"
                    className="me-1 mt-0"
                    alt="sivamfarms instagram"
                  />
                  <a
                    className="text-decoration-none"
                    href="https://instagram.com/sivamfarms.organics?igshid=MzRlODBiNWFlZA=="
                    target="blank"
                  >
                    sivamfarms.organics
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-4 pt-5">
              <h2 className="h2 text-light border-bottom pb-3 border-light">
                Products
              </h2>
              <ul className="list-unstyled text-light footer-link-list">
                <li>
                  <Link className="text-decoration-none" to="/herbalProduct">
                    Herbal Products
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/gardenProduct">
                    Garden Products
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/yogaProduct">
                    Yoga Products
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/spices">
                    Spices
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/foodProduct">
                    Food Products
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-md-4 pt-5">
              <h2 className="h2 text-light border-bottom pb-3 border-light">
                Further Info
              </h2>
              <ul className="list-unstyled text-light footer-link-list">
                <li>
                  <Link className="text-decoration-none" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/about">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/policy">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/terms">
                    Terms and Conditions
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="w-100 bg-black py-3">
          <div className="container">
            <div className="row pt-2">
              <div className="d-flex justify-content-between col-12">
                <p className="text-left text-light">
                  Copyright &copy; 2022 Sivam Farms
                </p>
                <a
                  href="https://vebbox.com/"
                  className="text-decoration-none text-light"
                >
                  Developed by vebbox software solutions with passion
                  <BsFillSuitHeartFill
                    className="ms-1"
                    style={{ color: "#c0392b", marginTop: "-5px" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
