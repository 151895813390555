import React from "react";
import { Link } from "react-router-dom";
import SpicesSingleProduct from "./SpiceCard";
import { Breadcrumbs } from "./../../Breadcrumb/index";

export default function Spices() {
  return (
    <div className="productPage">
      <div class="container py-5">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <Breadcrumbs currentPathName="Spices" />
              <div class="col-md-6">
                <ul class="list-inline shop-top-menu pb-3 pt-1">
                  <li class="list-inline-item">
                    <Link
                      class="h3 text-dark text-decoration-none mr-3 text-uppercase"
                      to="/spices">
                      Spices
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <SpicesSingleProduct />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
