import React, { useContext } from "react";
// import { GoogleLogout } from "react-google-login";
import { onLogoutSuccess } from "./globalMethods";
import { FcGoogle } from "react-icons/fc";
import { ToastContainer } from "react-toastify";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";

export default function UserLogout() {
  const { userLogout } = useContext(UserContext);
  let nav = useNavigate();

  const logOut = () => {
    googleLogout();
    userLogout();
    onLogoutSuccess();
    nav("/");
  };

  return (
    <>
      <button onClick={logOut} className="px-3 py-2 loginGoogleBtn">
        <FcGoogle className="me-2" style={{ marginTop: "-4px" }} />
        Logout
      </button>
      {/* <GoogleLogout
        jsSrc="https://apis.google.com/js/api.js"
        clientId={clienId}
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            className="px-3 py-2 loginGoogleBtn">
            <FcGoogle className="me-2" style={{ marginTop: "-4px" }} />
            Logout
          </button>
        )}
        buttonText="Logout"
        onLogoutSuccess={() => logOut()}
      /> */}
      <ToastContainer />
    </>
  );
}
