import React, { useEffect, useState } from "react";
import { Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { SERVER_PATH } from "./../../config/env";

const ProductsOfTheMonth = () => {
  const [POMData, setPOMData] = useState([]);

  const fetchPOMData = async () => {
    await axios
      .post(`${SERVER_PATH}get/product_of_month.php`, {})
      .then((res) => {
        setPOMData(res.data);
      });
  };

  useEffect(() => {
    fetchPOMData();
  }, []);

  return (
    <>
      {POMData.map((d, i) => {
        return (
          <Col key={i} className="col-12 col-md-4 p-4">
            <Card style={{ minHeight: "470px" }}>
              <Card.Img
                variant="top"
                src={d.img1}
                alt=""
                style={{ height: "230px", objectFit: "cover" }}
              />
              <Card.Body>
                <Card.Title>{d.product_name}</Card.Title>
                <Card.Text
                  style={{
                    height: "120px",
                    overflow: "hidden",
                  }}>
                  {d.description}
                </Card.Text>
                <Button
                  as={Link}
                  to={`/product/${d.product_id}`}
                  className="text-decoration-none"
                  variant="success">
                  View Product
                </Button>
              </Card.Body>
            </Card>
          </Col>
        );
      })}
    </>
  );
};

export default ProductsOfTheMonth;
