import React, { useEffect, useState } from "react";
import Login from "../../Login";
import { getLocalStorage } from "../../../utils/helper";

export const PrivateRouter = (props) => {
  const isLogin = getLocalStorage("uid") ? true : false;
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    setIsLoad(true);
  }, [isLogin]);

  if (isLogin) {
    return props.children;
  }
  return <>{isLoad && <Login />}</>;
};
