// Development

// export const SERVER_URL = "http://192.168.1.4";
// export const SERVER_PATH = `${SERVER_URL}/ecom/controllers/api/`;

// export const SERVER_URL = "https://vebbox.com/ecom_b";
// export const SERVER_PATH = `${SERVER_URL}/ecom/controllers/api/`;

// Production
export const SERVER_URL = "https://sivamfarms.com/ecom_b";
export const SERVER_PATH = `${SERVER_URL}/ecom/controllers/api/`;
